<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"/>
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"/>
              <b-button variant="primary" :to="{ name: 'activityCreate' }">
                <span class="text-nowrap">{{ $t("Create Activity") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="activityData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template slot="my-link" slot-scope="data">
          <b-link :href="data.item.link">{{ $t("link") }}</b-link>
        </template>

        <!-- :variant="activity_name.color" -->
        <template #cell(activity_types)="data">
          <b-badge
            style="margin: 3px; padding-top: 5px; min-width: 50px"
            :variant="activity.color"
            v-for="activity in data.value"
            :key="activity.activity_type_name">
            {{ activity.activity_type_name }}
          </b-badge>
        </template>

        <template #cell(name)="data">

            <span :style="`color:${data.item.activity_color};  margin :3px; padding-top:5px; min-width:50px`"> {{data.item.name}}</span>

        </template>

            <template #cell(is_active)="data">
             <b-badge  :variant="`${data.item.is_active ==1 ?'light-success':'light-danger'}`"> {{data.item.is_active ==1 ?$t("Active"):$t("Passive")}}</b-badge>

        </template>


        <template #cell(işlemler)="data">

          <template v-if="isMobile===true">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'activityEdit', params: { activityID:data.item.activity_id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{$t("Edit")}}</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item :to="{ name:'activityPriceList', params: {activityID: data.item.activity_id } }">
                <feather-icon icon="UsersIcon" />
                <span class="align-middle ml-50">{{$t("Price list")}}</span>
              </b-dropdown-item> -->

              <b-dropdown-item   @click="activityActiveChange(data.item.activity_id,data.item.is_active, data.index)"
               v-if="data.item.activity_type_id != '768027a8-6eb5-42f4-9e6e-b15f544439d0'">
                <feather-icon icon="UserXIcon" />
                  <span class="align-middle ml-50">{{data.item.is_active==1? $t("Deactivate"):$t("Activate")}}</span>

              </b-dropdown-item>
            </b-dropdown>
          </template>

           <template v-if="isMobile===false">
            <b-button
              size="sm"
              class="mr-1"
              variant="primary"
              :to="{ name: 'activityEdit', params: { activityID:data.item.activity_id } }"
              >{{ $t("Edit") }}</b-button
            >

            <b-button
              v-if="data.item.activity_type_id != '768027a8-6eb5-42f4-9e6e-b15f544439d0'"
              size="sm"
              :variant="data.item.is_active === false ? 'success' :'danger'"
              @click="activityActiveChange(data.item.activity_id,data.item.is_active, data.index)"
              >{{ data.item.is_active === false ? $t("Activate")  : $t("Deactivate") }}</b-button>
          </template>

        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6" sm=""
            class="d-flex align-items-left justify-content-right justify-content-sm-start radius 0">
          <b-form-select v-model="selected" :options="options" @change="changeFiltering" class="select-config">
          </b-form-select>
          </b-col>
          <small v-if="$i18n.locale === 'tr'" class="mt-1">içerisindeki {{total_item}} kayıttan {{currentPage*1}}-{{currentPage*perPage}} arası ({{currentPage}}.sayfa) gösteriliyor </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{total_item}} records</small>
          <b-col cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius 0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total_item"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";


export default {
  name: "activityList",
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
    vSelect
  },
  data() {
    return {
      bgcolor: "primary",
      searchQuery: "",
      currentPage: null, //
      total_item: null,
      isSortDirDesc: true,
      sortBy: "id",
      search_query: "",
      perPage: 25,
      locale: this.$i18n.locale,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "name", label: this.$t("ACTIVITY NAME"), sortable: true},
        // {
        //   key: "activity_types",
        //   label: this.$t("Activity Types"),
        //   sortable: false,
        // },
        // { key: "is_active", label: this.$t("Status"), sortable: false },
        { key: "işlemler", label: this.$t("Transactions"), sortable: false},
      ],
      isActive:0,//tüm aktiviteleri listele
      activityData: [],
      selected: 0,
      options: [
          { text: this.$t('All Activities'), value: 0},
          { text: this.$t('Active Activites'), value: 1 },
          { text: this.$t('Deactive Activites'), value: 2 },
        ],
        companyBranchId: ''
    };
  },
  computed: {
    filtered_activity_data() {
      let data = [];
      this.activityData.map((x) => {
        if (x.name.toUpperCase().includes(this.searchQuery.toUpperCase())) {
          data.push(x);
          this.total_item =data.length;
          this.currentPage=1;
        }
      });
      return data
    },
  },
  watch: {
    activityData(val) {
      this.total_item = val.length;
    },
  },
  methods: {
    changeFiltering(arg)
    {
        if (arg == 0)//isActive =0 tümünü listele
        {
          // console.log({"Tümü":arg})
          this.isActive=0;
          this.getActivityData(this.companyBranchId,this.isActive);
        }
        else if (arg == 1)//isActive =1 aktifleri listele
        {
            // console.log({"Aktif":arg})
          this.isActive=1;
          this.getActivityData(this.companyBranchId,this.isActive);
        } else if (arg == 2)//isActive =1 aktif olmayanları listele
        {
           // console.log({"Pasif":arg})
          this.isActive=2;
          this.getActivityData(this.companyBranchId,this.isActive);
        } else
        {
          this.isActive=0;
          this.getActivityData(this.companyBranchId,this.isActive);
        }
    },
    getActivityData(CompanyBranchId,isActive) {

      this.activityData=[];
      DataBase.StudioService.GetActivities(CompanyBranchId,isActive).then(
        (res) => {
          if (!res.IsSuccess)
                return;

              res.Result.map((el) => {
                  this.activityData.push({
                  company_branch_id:el.CompanyBranchId,
                  activity_id: el.ActivityId,
                  name: el.Name,
                  activity_color: el.ActivityColor,
                  activity_time: el.ActivityTime,
                  is_active:el.IsActive
                  })
              })

            // console.log(this.activityData);

          this.total_activity = this.activityData.length;
          this.currentPage = 1;
        }
      );
    },

    activityActiveChange(activity_id, is_active, index)
    {
      //is_active = is_active == 0 ? true : is_active == true ? "0" : true;

      var _isActive=!is_active;


      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want To Change Activity Status?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText:  this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) =>
      {
        if (result.value)
        {
          DataBase.StudioService.ActivityIsActiveChange(activity_id,_isActive
            ).then((res) => {
              if (!res.IsSuccess)
              {
                this.$functions.Messages.error('Action failed!');
                return;
              }
                this.$functions.Messages.success('Activity status changed');
                this.activityData[index].is_active=!is_active;
            });
        }
      });
    },
  },
  created() {
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
    this.getActivityData(this.companyBranchId,this.isActive);
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/activityList.scss" scoped> </style>
